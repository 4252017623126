import "core-js/stable";

import "@mdi/font/css/materialdesignicons.min.css";
import * as Sentry from "@sentry/vue";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { createPinia, PiniaVuePlugin } from "pinia";
import Vue, { provide } from "vue";
import Toasted from "vue-toasted";
import VueTour from "vue-tour";
import "vue-tour/dist/vue-tour.css";

import numeral from "numeral";
import VueHighcharts from "vue-highcharts";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { createDefaultClient, createProvider } from "./vue-apollo";

import "./plugins/vee-validate";
import "./plugins/index";

import "./assets/scss/styles.scss";
import "./assets/styles/roboto_material_icons.css";

Vue.config.productionTip = false;
Vue.use(Toasted, { duration: 2000 });

Vue.use(VueTour);

Vue.filter("number", (value) => {
  if (Number.isInteger(value)) {
    return numeral(+value).format().replace(/,/g, " ");
  }
  return value;
});

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
});

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

new Vue({
  pinia,
  router,
  store,
  vuetify,
  apolloProvider: createProvider(),
  setup() {
    provide(DefaultApolloClient, createDefaultClient());
    provide("toasted", Vue.prototype.$toasted);
  },
  render: (h) => h(App),
}).$mount("#app");

Vue.use(VueHighcharts);
