import { getQueryParam } from "../../../services/queryParams";
import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const initialViewId = getQueryParam("viewId");
const initialResourceId = getQueryParam("resourceId");

const state = {
  selectedResourceAndView: {
    resourceId: initialResourceId,
    viewId: initialViewId,
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
