import { useStatisticsStore } from "../../../stores/statistics";

const setActiveResourceAndView = ({ commit }, viewAndResource) => {
  commit("SET_ACTIVE_RESOURCE_AND_VIEW", viewAndResource);
};

const changeActiveResourceAndView = ({ commit }, viewAndResource) => {
  commit("SET_ACTIVE_RESOURCE_AND_VIEW", viewAndResource);
  const statisticsStore = useStatisticsStore();
  statisticsStore.resetDimensionsFilters();
};

export default {
  setActiveResourceAndView,
  changeActiveResourceAndView,
};
