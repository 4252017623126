import gql from "graphql-tag";
import { createDefaultClient } from "../../../vue-apollo";

const defaultClient = createDefaultClient();

const createVPAIDTemplate = async (
  _,
  { resourceId, title, errorURL, impressions, creative },
) => {
  const res = await defaultClient.mutate({
    fetchPolicy: "no-cache",
    mutation: gql`
      mutation ($data: CreateVPAIDTemplateInput!) {
        createVPAIDTemplate(data: $data) {
          id
        }
      }
    `,
    variables: {
      data: {
        resourceId,
        title,
        errorURL,
        impressions,
        creative,
      },
    },
  });
  return res.data.createVPAIDTemplate.title;
};

const deleteVPAIDTemplate = async (_, { id, resourceId }) => {
  const res = await defaultClient.mutate({
    fetchPolicy: "no-cache",
    mutation: gql`
      mutation ($id: String!, $resourceId: String!) {
        deleteVPAIDTemplate(id: $id, resourceId: $resourceId) {
          id
          resourceId
        }
      }
    `,
    variables: {
      id,
      resourceId,
    },
  });
  return res.data.deleteVPAIDTemplate.id;
};

const fetchVPAIDTemplates = async (_, { filter, pagination }) => {
  const res = await defaultClient.query({
    fetchPolicy: "no-cache",
    query: gql`
      query ($filter: VPAIDTemplatesFilter!, $pagination: PaginationInput!) {
        VPAIDTemplates(filter: $filter, pagination: $pagination) {
          count
          rows {
            id
            resourceId
            link
            title
            errorURL
            impressions {
              id
              url
            }
            creative {
              duration
              trackingEvents {
                id
                url
              }
              mediaFileUrl
              videoClickUrl
            }
            created
            updated
          }
        }
      }
    `,
    variables: {
      filter,
      pagination,
    },
  });

  return res.data.VPAIDTemplates.rows;
};

export default {
  createVPAIDTemplate,
  fetchVPAIDTemplates,
  deleteVPAIDTemplate,
};
