import { parse, stringify } from "query-string";

export const setQueryParams = (paramsObj = {}) => {
  const { search, protocol, host, pathname } = window.location;
  const params = parse(search);
  const newParams = stringify({ ...params, ...paramsObj });
  const newUrl = `${protocol}//${host}${pathname}?${newParams}`;
  window.history.pushState({ path: newUrl }, "", newUrl);
};

export const getQueryParam = (paramName = "") => {
  const { search } = window.location;

  const params = parse(search);

  return params[paramName];
};

export const removeQueryParam = (paramName = "") => {
  const { search, protocol, host, pathname } = window.location;
  const params = parse(search);
  delete params[paramName];

  const newParams = stringify(params);

  const newUrl = `${protocol}//${host}${pathname}?${newParams}`;
  window.history.pushState({ path: newUrl }, "", newUrl);
};

export const getAllColumnFilters = () => {
  const { search } = window.location;

  const params = parse(search);
  const filtersEntries = Object.entries(params)
    .filter(([key]) => key.startsWith("filter_"))
    .map(([key, value]) => [key.replace("filter_", ""), value.split(",")]);
  return Object.fromEntries(filtersEntries);
};

export const setAllColumnFilters = (filters) => {
  const { search, protocol, host, pathname } = window.location;
  const params = parse(search);

  const filterlessParams = Object.fromEntries(
    Object.entries(params).filter(([key]) => !key.startsWith("filter_")),
  );
  const paramsWithNewFilters = Object.fromEntries(
    Object.entries(filters)
      .filter(([, value]) => !!value.length)
      .map(([key, value]) => [`filter_${key}`, value.join(",")]),
  );
  const newParams = stringify({ ...filterlessParams, ...paramsWithNewFilters });
  const newUrl = `${protocol}//${host}${pathname}?${newParams}`;
  window.history.pushState({ path: newUrl }, "", newUrl);
};
