import gql from "graphql-tag";
import { createDefaultClient } from "../../../vue-apollo";

const defaultClient = createDefaultClient();

const createRulesGroup = async (_, { name, description, typesIds, rules }) => {
  const res = await defaultClient.mutate({
    fetchPolicy: "no-cache",
    mutation: gql`
      mutation ($data: CreateRulesGroupInput!) {
        createRulesGroup(data: $data) {
          id
        }
      }
    `,
    variables: {
      data: {
        name,
        description,
        typesIds,
        rules,
      },
    },
  });

  return res.data.createRulesGroup.id;
};

const updateRulesGroup = async (
  _,
  { id, name, description, typesIds, rules },
) => {
  const res = await defaultClient.mutate({
    fetchPolicy: "no-cache",
    mutation: gql`
      mutation ($rulesGroupId: ID!, $data: UpdateRulesGroupInput!) {
        updateRulesGroup(rulesGroupId: $rulesGroupId, data: $data) {
          id
        }
      }
    `,
    variables: {
      rulesGroupId: id,
      data: {
        name,
        description,
        typesIds,
        rules,
      },
    },
  });

  return res.data.updateRulesGroup.id;
};

const fetchRulesGroups = async ({ commit }) => {
  const res = await defaultClient.query({
    fetchPolicy: "no-cache",
    query: gql`
      query {
        rulesGroups {
          rows {
            id
            name
            description
            types {
              id
              name
            }
            rules {
              trafficSource
              type
              value
            }
          }
        }
      }
    `,
  });

  commit("SET_RULES_GROUPS", res.data.rulesGroups.rows);
};

const deleteRulesGroup = async (_, { rulesGroupId }) => {
  const res = await defaultClient.mutate({
    fetchPolicy: "no-cache",
    mutation: gql`
      mutation ($rulesGroupId: ID!) {
        deleteRulesGroup(rulesGroupId: $rulesGroupId) {
          id
        }
      }
    `,
    variables: { rulesGroupId },
  });
  return res.data;
};

export default {
  createRulesGroup,
  updateRulesGroup,
  fetchRulesGroups,
  deleteRulesGroup,
};
