import gql from "graphql-tag";
import { createDefaultClient } from "../../../vue-apollo";

const defaultClient = createDefaultClient();

const uploadFile = async (_, { file, resourceId }) => {
  const res = await defaultClient.mutate({
    fetchPolicy: "no-cache",
    mutation: gql`
      mutation ($data: UploadFileInput!) {
        uploadFile(data: $data) {
          filename
        }
      }
    `,
    variables: {
      data: {
        file,
        resourceId,
      },
    },
  });
  return res.data.uploadFile.filename;
};

const deleteFile = async (_, { id }) => {
  const res = await defaultClient.mutate({
    fetchPolicy: "no-cache",
    mutation: gql`
      mutation ($id: String!) {
        deleteFile(id: $id) {
          id
        }
      }
    `,
    variables: {
      id,
    },
  });
  return res.data.deleteFile.id;
};

const fetchFiles = async ({ commit }, { filter, pagination }) => {
  const res = await defaultClient.query({
    fetchPolicy: "no-cache",
    query: gql`
      query ($filter: GetFilesFilter!, $pagination: PaginationInput!) {
        files(filter: $filter, pagination: $pagination) {
          count
          rows {
            id
            filename
            link
            created
          }
        }
      }
    `,
    variables: {
      filter,
      pagination,
    },
  });

  commit("SET_FILES", res.data.files.rows);
};

export default {
  uploadFile,
  fetchFiles,
  deleteFile,
};
