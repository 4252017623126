import {
  getQueryParam,
  removeQueryParam,
  setQueryParams,
} from "../queryParams";

const ORDER_PARAM_NAME = "order";

export function getOrder() {
  const param = getQueryParam(ORDER_PARAM_NAME);
  if (!param) {
    return null;
  }
  const [name, type] = param.split(":");
  return name && type ? { name, type } : null;
}

export function setOrder(order) {
  setQueryParams({ [ORDER_PARAM_NAME]: `${order.name}:${order.type}` });
}

export function removeOrder() {
  removeQueryParam(ORDER_PARAM_NAME);
}
