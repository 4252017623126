import { getQueryParam, setQueryParams } from "../queryParams";

const LIMIT_PARAM_NAME = "limit";

export function getLimit() {
  return getQueryParam(LIMIT_PARAM_NAME);
}

export function setLimit(limit) {
  setQueryParams({ [LIMIT_PARAM_NAME]: limit });
}
