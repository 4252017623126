import moment from "moment";
import { defineStore } from "pinia";

import {
  getAllColumnFilters,
  getQueryParam,
  setAllColumnFilters,
  setQueryParams,
} from "../services/queryParams";
import {
  getLimit,
  getOrder,
  getPage,
  removeOrder,
  setLimit,
  setOrder,
  setPage,
} from "../services/statisticsQueryParams";

const PARTIAL_MATCH_PREFIX = "%";

function isExpressionPartialMatch(expression) {
  return expression.startsWith(PARTIAL_MATCH_PREFIX);
}

function sanitizePartialMatchExpression(expression) {
  return expression.slice(PARTIAL_MATCH_PREFIX.length);
}

function getDimensionGroupFilter(dimension, expressions) {
  const strictExpressions = expressions.filter(
    (expression) => !isExpressionPartialMatch(expression),
  );
  const partialMatchExpressions = expressions
    .filter(isExpressionPartialMatch)
    .map(sanitizePartialMatchExpression);
  const filters = [];
  if (strictExpressions.length) {
    filters.push({
      matchType: "FILTER_MATCH_IN",
      value: dimension,
      expressions: strictExpressions,
    });
  }
  if (partialMatchExpressions.length) {
    filters.push({
      matchType: "FILTER_MATCH_LIKE",
      value: dimension,
      expressions: partialMatchExpressions,
    });
  }
  return {
    matchType: "FILTER_MATCH_OR",
    filters,
    groups: [],
  };
}

export const useStatisticsStore = defineStore("statistics", {
  state: () => {
    const dateFilters = getQueryParam("dateFilters");
    const columns = getQueryParam("columns");
    return {
      filters: {
        date: dateFilters
          ? dateFilters.split(",")
          : [
              moment().subtract(6, "days").format("YYYY-MM-DD"),
              moment().format("YYYY-MM-DD"),
            ],
        dimensions: getAllColumnFilters(),
      },
      activeColumns: columns ? columns.split(",") : [],
      page: getPage(),
      limit: getLimit(),
      order: getOrder(),
      fullscreen: false,
    };
  },
  getters: {
    dateFilters: (state) => state.filters.date,
    dimensionFilters: (state) => state.filters.dimensions,
    groupFilters: (state) => {
      const { date, dimensions } = state.filters;
      return [
        {
          matchType: "FILTER_MATCH_AND",
          filters: [
            {
              value: "date",
              matchType: "FILTER_MATCH_GREATER_EQUAL",
              expressions: [date[0]],
            },
            {
              value: "date",
              matchType: "FILTER_MATCH_LESS_EQUAL",
              expressions: [date[1] || date[0]],
            },
          ],
          groups: Object.entries(dimensions).map(([dimension, expressions]) =>
            getDimensionGroupFilter(dimension, expressions),
          ),
        },
      ];
    },
  },
  actions: {
    setDateFilter({ dateRange }) {
      const sortedDateRange = dateRange.sort(
        (a, b) => moment(a).unix() - moment(b).unix(),
      );
      this.filters.date = sortedDateRange;
      setQueryParams({ dateFilters: sortedDateRange.join(",") });
    },
    setDimensionFilter({ dimension, expressions }) {
      const newFiltersState = {
        ...this.filters.dimensions,
        [dimension]: expressions,
      };
      this.filters.dimensions = newFiltersState;
      setAllColumnFilters(newFiltersState);
    },
    resetDimensionsFilters() {
      this.filters.dimensions = [];
      setAllColumnFilters({});
    },
    setActiveColumns(columns) {
      if (columns && columns.length) {
        this.activeColumns = columns;
        setQueryParams({ columns: columns.join(",") });
      }
    },
    setPage(page) {
      this.page = page;
      setPage(page);
    },
    setLimit(limit) {
      this.limit = limit;
      setLimit(limit);
    },
    setOrder(order) {
      this.order = order;
      if (order) {
        setOrder(order);
      } else {
        removeOrder();
      }
    },
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
  },
});
