export default {
  request(req, token) {
    this.drivers.http.setHeaders.call(this, req, {
      Authorization: `Bearer ${token}`,
    });
  },
  response(res) {
    return ~res.request.responseURL.indexOf("auth") ? res.data.token : null;
  },
};
