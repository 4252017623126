const getActiveView = ({ selectedResourceAndView }, getters) =>
  getters.getActiveResource?.views.find(
    (view) => view.id === selectedResourceAndView.viewId,
  );

const getActiveResource = (
  { selectedResourceAndView },
  getters,
  rootState,
  rootGetters,
) =>
  rootGetters["resources/getResourceById"](selectedResourceAndView.resourceId);

const getActiveTypes = (state, getters) => {
  const activeResource = getters.getActiveResource;
  return [
    ...(activeResource?.type?.dimensions ?? []),
    ...(activeResource?.type?.metrics ?? []),
  ];
};

/**
 *
 * @param state
 * @param getters
 * @return {boolean}
 */
function isActiveResourceHasTypeMobile(state, getters) {
  const activeResource = getters.getActiveResource;
  return (
    activeResource &&
    activeResource.type &&
    activeResource.type.name === "Mobile"
  );
}

/**
 *
 * @param state
 * @param getters
 * @return {boolean}
 */
function isActiveResourceHasTypeMedia(state, getters) {
  const activeResource = getters.getActiveResource;
  return (
    activeResource &&
    activeResource.type &&
    activeResource.type.name !== "Mobile"
  );
}

export default {
  getActiveView,
  getActiveTypes,
  getActiveResource,
  isActiveResourceHasTypeMobile,
  isActiveResourceHasTypeMedia,
};
