export { AdTemplateType } from "@marvin/core/dist/constants";
export {
  FilterMatchType,
  SpecificConditionType,
  SpecificValueType,
} from "@marvin/core/dist/shared/grpc/generated/resource";
export {
  UserRole,
  UserStatus,
} from "@marvin/core/dist/shared/grpc/generated/user";

export const DecodedUserRole = {
  USER_ADMIN: "USER_ADMIN",
  USER_USER: "USER_USER",
};

export const DEFAULT_REMEMBERED_DATA = "{}";
