import gql from "graphql-tag";
import { createDefaultClient } from "../../../vue-apollo";

const defaultClient = createDefaultClient();

const fetchUsers = async ({ commit }) => {
  const res = await defaultClient.query({
    fetchPolicy: "no-cache",
    query: gql`
      query {
        users {
          count
          rows {
            id
            email
            role
            status
            created
            updated
          }
        }
      }
    `,
  });
  commit("SET_USERS", res.data.users.rows);
};

const updateUser = async (_, { id, email, password, role, status }) => {
  const res = await defaultClient.mutate({
    fetchPolicy: "no-cache",
    mutation: gql`
      mutation ($id: ID!, $data: UpdateUserInput!) {
        updateUser(id: $id, data: $data) {
          id
          email
          role
          status
          created
          updated
        }
      }
    `,
    variables: {
      id,
      data: {
        email,
        password,
        role,
        status,
      },
    },
  });
  return res.data.updateUser.email;
};

const createUser = async (_, { email, password, role, status }) => {
  const res = await defaultClient.mutate({
    fetchPolicy: "no-cache",
    mutation: gql`
      mutation ($data: CreateUserInput!) {
        createUser(data: $data) {
          id
          email
          role
          status
          created
          updated
        }
      }
    `,
    variables: {
      data: {
        email,
        password,
        role,
        status,
      },
    },
  });
  return res.data.createUser.email;
};

export default {
  fetchUsers,
  updateUser,
  createUser,
};
