import Vue from "vue";
import Vuex from "vuex";

import users from "./modules/users";
import resources from "./modules/resources";
import resourcesViews from "./modules/resourcesViews";
import settings from "./modules/settings";
import viewers from "./modules/viewers";
import types from "./modules/types";
import files from "./modules/files";
import adTemplates from "./modules/adTemplates";
import dashboard from "./modules/dashboard";
import rulesGroups from "./modules/rulesGroups";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    users,
    resources,
    resourcesViews,
    settings,
    viewers,
    types,
    files,
    adTemplates,
    dashboard,
    rulesGroups,
  },
});
